import {AxiosRequestConfig} from "axios";
import {axios_get, axios_patch} from "../../common/services/api.configuration";
import {SettingsDto} from "../index";

export const SETTINGS_ROUTE = "/settings"
export const TEST_EMAIL_ROUTE = "/settings/test/email"
export const TEST_SMS_ROUTE = "/settings/test/sms"

class SettingsApi {

    getSettings(): Promise<SettingsDto> {
        const config: AxiosRequestConfig = {};
        return axios_get(SETTINGS_ROUTE, config);
    }

    updateSettings(settings: Partial<SettingsDto>): Promise<SettingsDto> {
        const config: AxiosRequestConfig = {};
        return axios_patch(SETTINGS_ROUTE, settings, config);
    }

    testEmail(): Promise<boolean> {
        const config: AxiosRequestConfig = {};
        return axios_get(TEST_EMAIL_ROUTE, config);
    }

    testSms(): Promise<boolean> {
        const config: AxiosRequestConfig = {};
        return axios_get(TEST_SMS_ROUTE, config);
    }
}

const mapToSettings = (data: any): SettingsDto => {
    return data;
}

export const serviceSettingsApi = new SettingsApi();
